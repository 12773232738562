import { ideaConstants } from '../constants';
import update from 'react-addons-update';

const initialState = {
  ideas: [],
  loading: false,
  deleteLoading: false,
  errorToggleTimeline: false,
  ideaDetails: {},
  statusIdea: {},
  pendingIdeas: 0,
  statusIdeas: {},
  canvas: [
    {
      key: 'Q1',
      answer: '',
    },
    {
      key: 'Q2',
      answer: '',
    },
    {
      key: 'Q3',
      answer: '',
    },
    {
      key: 'Q4',
      answer: '',
    },
    {
      key: 'Q5',
      answer: '',
    },
    {
      key: 'Q6',
      answer: '',
    },
    {
      key: 'Q7',
      answer: '',
    },
    {
      key: 'Q8',
      answer: '',
    },
    {
      key: 'Q9',
      answer: '',
    },
    {
      key: 'Q10',
      answer: '',
    },
  ],
  approved: {
    images: [],
    canvas: [],
    impactIndicator: null,
    typeRequest: null,
    deadlineImplementation: null,
    evaluation: '',
    innovationLevel: 2,
    patenteable: null,
    _id: '',
    approved: null,
    personalizedClassifications: [],
    fieldsErrors: false,
    qualification: "EXECUTION_QUEUE",
  },
  delegate: {
    businessUnitLocal: [],
    canvas: [],
    deadlineImplementation: null,
    evaluatedInput: '',
    images: [],
    impactIndicator: null,
    typeRequest: null,
    _id: '',
  },
  paging: {
    page: 1,
    limit: 10,
    skip: 0,
    sort: { created: -1 },
    total: 0,
    search: '',
    statusCode: [
      'CONCLUDED',
      'CANCELED',
      'AWAITING_QUALIFICATION',
      'UNQUALIFIED',
      'PATENT_PENDING',
      'EXECUTION_QUEUE',
      'EXECUTING',
      'QUALIFIED',
      'DRAFT',
      'PENDING_REVIEW'
    ],
  },
  page: "",
};

export function idea(state = initialState, action) {
  switch (action.type) {
    case ideaConstants.GET_USER_IDEAS_IMAGINE_REQUEST:
      return update(state, {
        loading: { $set: true },
      });

    case ideaConstants.GET_USER_IDEAS_IMAGINE_SUCCESS:
      return update(state, {
        ideas: { $set: action.payload.ideas.data },
        paging: {
          total: { $set: action.payload.ideas.paging.total },
          page: { $set: parseInt(action.payload.ideas.paging.page) },
          search: { $set: action.payload.query },
          limit: { $set: action.payload.ideas.paging.limit },
        },
        loading: {
          $set: false,
        },
      });

    case ideaConstants.GET_USER_IDEAS_IMAGINE_FAILURE:
      return state;

    case ideaConstants.GET_USER_IDEAS_IMAGINE_REQUEST_MOBILE:
      return update(state, {
        loading: { $set: true },
      });

    case ideaConstants.GET_USER_IDEAS_IMAGINE_SUCCESS_MOBILE:
      return update(state, {
        ideas: { $push: action.payload.ideas.data },
        paging: {
          total: { $set: action.payload.ideas.paging.total },
          page: { $set: parseInt(action.payload.ideas.paging.page) + 1 },
          search: { $set: action.payload.query },
          limit: { $set: action.payload.ideas.paging.limit },
        },
        loading: {
          $set: false,
        },
      });

    case ideaConstants.GET_USER_IDEAS_IMAGINE_FAILURE_MOBILE:
      return state;

    case ideaConstants.GET_IDEA_USER_DETAILS_REQUEST:
      return update(state, {
        loading: { $set: true },
      });

    case ideaConstants.GET_IDEA_USER_DETAILS_SUCCESS:
      return update(state, {
        ideaDetails: { $set: action.payload.idea },
        loading: { $set: false }
      });

    case ideaConstants.GET_IDEA_USER_DETAILS_FAILURE:
      return update(state, {
        loading: { $set: false },
      });

    case ideaConstants.DELETE_DRAFT_REQUEST: {
      return {
        ...state,
        deleteLoading: true
      }
    }

    case ideaConstants.DELETE_DRAFT_SUCCESS:
      const newStateIdeas = state.ideas.filter(
        (idea) => idea._id !== action.payload.id
      );
      return update(state, {
        ideas: { $set: newStateIdeas },
        deleteLoading: { $set: false },
      });

    case ideaConstants.DELETE_DRAFT_FAILURE:
      return state;

    case ideaConstants.TOGGLE_FILTER:
      return update(state, {
        paging: {
          statusCode: { $set: action.payload.filter },
          deleteLoading: { $set: false },
        },
      });

    case ideaConstants.SET_ID_APPROVED:
      return update(state, {
        approved: {
          _id: { $set: action.payload.id },
        },
      });

    case ideaConstants.SET_PERSONALIZED_CLASSIFICATION_APPROVED:
      return update(state, {
        approved: {
          personalizedClassifications: { $set: action.payload.personalizedClassifications },
        },
      });

    case ideaConstants.SET_PERSONALIZED_CLASSIFICATION_IDEA_DETAILS:
      return update(state, {
        ideaDetails: {
          personalizedClassifications: { $set: action.payload.personalizedClassifications },
        },
      });

    case ideaConstants.SET_EVALUATION_APPROVED:
      return update(state, {
        approved: {
          evaluation: { $set: action.payload.evaluation },
        },
      });

    case ideaConstants.SET_APPROVED:
      return update(state, {
        approved: {
          approved: { $set: action.payload.approved },
        },
      });

    case ideaConstants.CLEAR_APPROVED:
      return update(state, {
        approved: {
          _id: { $set: '' },
          evaluation: { $set: '' },
          approved: { $set: null },
          personalizedClassifications: { $set: [] },
        },
      });

    case ideaConstants.SET_ID_DELEGATE:
      return update(state, {
        delegate: {
          _id: { $set: action.payload.id },
        },
      });

    case ideaConstants.CLEAR_DELEGATE:
      return update(state, {
        delegate: {
          _id: { $set: '' },
          evaluatedInput: { $set: '' },
        },
      });

    case ideaConstants.SET_BUL:
      return update(state, {
        ideaDetails: {
          businessUnitLocal: { $set: action.payload.buls },
        },
      });

    case ideaConstants.CLEAR_IDEA_DETAILS: {
      return update(state, {
        ideaDetails: { $set: {} },
      });
    }

    case ideaConstants.GET_PENDING_IDEAS_SUCCESS: {
      return update(state, {
        pendingIdeas: { $set: action.payload.data },
      });
    }

    case ideaConstants.GET_STATUS_IDEAS_SUCCESS: {
      return update(state, {
        statusIdeas: { $set: action.payload.data },
      });
    }

    case ideaConstants.SET_IDEA_DETAILS_PAGE: {
      return {
        ...state,
        page: action.payload
      }
    }

    case ideaConstants.TOGGLE_IDEA_IN_TIMELINE_REQUEST: {
      return {
        ...state,
        loading: true,
        ideaDetails: {
          ...state.ideaDetails,
          timeline: action.payload
        }
      }
    }

    case ideaConstants.TOGGLE_IDEA_IN_TIMELINE_SUCCESS: {
      return {
        ...state,
        loading: false,
      }
    }

    case ideaConstants.TOGGLE_IDEA_IN_TIMELINE_FAILURE: {
      return {
        ...state,
        loading: false,
        errorToggleTimeline: true
      }
    }

    case ideaConstants.SET_IDEA_QUALIFICATION: {
      return { 
        ...state,
        approved: {
          ...state.approved,
          qualification: action.payload
        }
      }
    }

    case ideaConstants.SET_QUALIFICATION_FIELDS_ERRORS: {
      return { 
        ...state,
        approved: {
          ...state.approved,
          fieldsErrors: !state.approved.fieldsErrors
        }
      }
    }

    default:
      return state;
  }
}
