import React, { useEffect } from 'react';
import {
  Card,
  CardActions,
  CardContent,
  makeStyles,
  Box,
  Typography,
  CircularProgress,
} from '@material-ui/core';


import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import CategoryIcon from '@material-ui/icons/Category';
import { personalizedClassificationActions } from '../../actions';


const useStyles = makeStyles((theme) => ({
  description: {
    fontWeight: 600,
    fontSize: 20,
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '32px',
  },
  iconDiscription: {
    backgroundColor: theme.palette.primary.light,
    width: 40,
    height: 40,
    borderRadius: '50%',
    marginRight: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  marginBetweenInputs: {
    margin: theme.spacing(2, 0),
  },
  labelRoot: {
    fontWeight: 600,
    lineHeight: 1,
  },
  about: {
    margin: theme.spacing(2, 0),
    position: 'relative',
  },
  characters: {
    margin: theme.spacing(1, 0),
    fontSize: '0.75rem',
    position: 'absolute',
    right: 10,
  },
  error: {
    color: theme.palette.auxiliary.red.main,
    marginLeft: 14,
    marginRight: 14,
    marginTop: 3,
    fontSize: '0.6428571428571428rem',
    fontWeight: 400,
    lineHeight: 1.66,
  },
  subtitle: {
    fontSize: 13,
    color: theme.palette.neutrals.low.medium,
    marginTop: 16,
  },
  dates: {
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    padding: '32px',
  },
  categoryTitle: {
    font: 'normal normal 600 14px/22px Poppins'
  },

  categoryItem: {
    font: 'normal normal normal 14px/22px Poppins'
  },
  add_category_button: {
    margin: '32px 32px'
  },
  trashButton: {
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start"
  },
  trashButtonWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },

}));

function ListClassificationPersonalized() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();

  const { personalizedClassification: {
    personalizedClassification,
    loading
  } } = useSelector(state => state);

  useEffect(() => {
    dispatch(personalizedClassificationActions.getPersonalizedClassification());
  }, [dispatch])



  return (
    loading ? (<Box display='flex' justifyContent='center' width='100%'><CircularProgress /></Box>) : (<Card>
      <CardActions className={classes.actions}>
        <Box display='flex' alignItems='center'>
          <Box className={classes.iconDiscription}>
            <CategoryIcon color="primary" />
          </Box>
          <Box className={classes.description}>{t('classificacao_das_ideias')}</Box>
        </Box>
      </CardActions>
      <Box>
        <CardContent className={classes.content}>
          <Box display='flex' flexDirection='row' >
            <Box
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              marginRight='32px'
              minWidth='320px' >
              <Typography className={classes.categoryTitle}>{t('categoria')}</Typography>
            </Box>
            <Box display='flex' justifyContent='space-between' alignItems='center'>
              <Typography className={classes.categoryTitle}>{t('descricao')}</Typography>
            </Box>
          </Box>
          {
            personalizedClassification?.map(({ name, description, _id }, index) => (
              <Box
                key={_id}
              >
                <Box
                  display='flex'
                  flexDirection='row'
                  marginTop='24px'
                  marginBottom='24px'

                >
                  <Box
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                    marginRight='32px'
                    minWidth='320px'
                  >
                    <Typography className={classes.categoryItem}>{name}</Typography>
                  </Box>
                  <Box display='flex' justifyContent='space-between' alignItems='center'>
                    <Typography className={classes.categoryItem}>{description}</Typography>
                  </Box>
                </Box>
                {personalizedClassification?.length !== index + 1 && (
                  <Box
                    height='1px'
                    borderBottom='1px solid #E7EAED'
                  >

                  </Box>
                )}
              </Box>
            ))
          }
        </CardContent>
      </Box>

    </Card>)
  );
}

export { ListClassificationPersonalized };
