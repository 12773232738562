import React, { useEffect, Fragment  } from 'react';
import {
  DialogContent,
  makeStyles,
  FormControl,
  FormLabel,
  DialogActions,
  Button,
  Hidden,
  Grid,
  ButtonBase,
  TextField
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { DialogCustomTitle } from './DialogCustomTitle';
import { 
  ideaCreateActions,
  ideaCreateDialogsActions,
  multiAreaCascadeActions
} from '../../actions';
import { useDispatch, useSelector } from 'react-redux';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { dialogCss } from './dialogCss';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { DialogCreateIdea } from './DialogCreateIdea';
import config from '../../config';
import { useWindowSize } from '../../hooks';
import { useTranslation } from 'react-i18next';
import { MultiAreaCascade } from "./../../components/Shared"

const useStyles = makeStyles((theme) => ({
  dividerBottom: {
    borderBottom: 'none',
  },
  formControl: {
    width: "100%"
  },
  addMoreIconWrapper: {
    width: 18,
    height: 18,
    marginRight: 8,
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main
  },
  addButton: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.primary.main
  },
  fieldsWrap: {
    [theme.breakpoints.down('xs')]: {
      "&:not(:first-child)": {
        borderTop: "1px solid rgb(170, 170, 170)"
      }
    }
  }
}));

function DialogMultiArea({ challenge, onClose }) {
  const classes = useStyles();
  const classesDialog = dialogCss();
  const { imagineLocals, Challenge } = useSelector((state) => state.domains);
  const idea = useSelector((state) => state.ideaCreate);
  const { fields } = useSelector(state => state.multiAreaCascade)
  const { dialogMultiArea, fromHistory, error } = useSelector(state => state.ideaCreateDialogs);
  const dispatch = useDispatch(); 
  const [width] = useWindowSize();
  const { t } = useTranslation();

  function createDraft() {
    if (idea.businessUnitLocal.length > 0) {
      dispatch(ideaCreateActions.createDraft(idea));
    }
  }

  function previous() {
    dispatch(ideaCreateDialogsActions.previousDialog())
  }

  function onSubmit() {
    if (fields.filter(field => field.selectedBusinessUnit).length > 0) {
      const multiAreaFields = fields.filter(item => item.selectedBusinessUnit).map(item => item.selectedBusinessUnit)
      dispatch(ideaCreateActions.setSelectedBusiness(multiAreaFields))

      if (fromHistory[fromHistory.length - 1] === 'sendIdea') {
        dispatch(ideaCreateDialogsActions.openDialogSendIdea());
        return;
      }

      if (challenge) {
        dispatch(ideaCreateDialogsActions.openDialogSendIdea('DIALOG_MULTI_AREA'));
      } else {
        if (Challenge && Challenge?.length > 0) {
        dispatch(ideaCreateDialogsActions.openDialogChallenge('DIALOG_MULTI_AREA'));
        } else {
          dispatch(ideaCreateDialogsActions.openDialogSendIdea('DIALOG_MULTI_AREA'));
        }
      }
    }
  }

  function handleAddAreaFIeld() {
    dispatch(multiAreaCascadeActions.addField(imagineLocals));
  }

  function close() {
    if (width < config.RESPONSIVE_MOBILE) {
      dispatch(ideaCreateDialogsActions.openDialogClose());
    } else {
      dispatch(ideaCreateActions.clearAll());
      dispatch(ideaCreateDialogsActions.close());
    }
    dispatch(multiAreaCascadeActions.clearAll());
  }
  return (
    <DialogCreateIdea maxWidth="lg" open={dialogMultiArea}>
      <DialogCustomTitle id="customized-dialog-title" onClose={close}>
        {t('qual_e_a_area_de_implementacao_da_sua_ideia')}
      </DialogCustomTitle>
      <DialogContent dividers className={classes.dividerBottom}>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel component="legend" className={classesDialog.subtitle}>
            {t('selecione_a_area_em_que_esta_ideia_podera_ser_implementada')}.{' '}
            {t('caso_necessario_inclua_novas_areas')}.
          </FormLabel>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <MultiAreaCascade 
                    hasDeleteButton={true} 
                    loadBusinessUnitLocals={dialogMultiArea && idea.selectedBusiness} 
                    clearAll={false}
                  />
                </Grid>
                { fields.filter(item => item.selectedBusinessUnit).length > 0 && (
                  <Grid item xs={12}>
                    <ButtonBase
                      variant="contained"
                      color="transparent"
                      className={classes.addButton}
                      onClick={handleAddAreaFIeld}
                    >
                      <div className={classes.addMoreIconWrapper}>
                        <AddIcon fontSize="small" style={{ color: "white" }} />
                      </div>
                      {t('incluir_area')}
                    </ButtonBase>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </FormControl>
      </DialogContent>
      <DialogActions className={classesDialog.containerAction}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div>
            {fromHistory.length > 0 && (
              <Button
                color="primary"
                onClick={previous}
                startIcon={<ArrowBackIcon />}
                className={classesDialog.button}
              >
                {t('voltar')}
              </Button>
            )}
          </div>
          <div>
            <Hidden smDown>
              <Button
                className={classesDialog.button}
                variant="outlined"
                color="primary"
                style={{ marginRight: 8 }}
                onClick={createDraft}
              >
                {t('salvar_rascunho')}
              </Button>
            </Hidden>
            <Button
              className={classesDialog.button}
              variant="contained"
              type="submit"
              color="primary"
              startIcon={<ArrowForwardIcon />}
              onClick={onSubmit}
            >
              {t('avancar')}
            </Button>
          </div>
        </div>
      </DialogActions>
    </DialogCreateIdea>
  );
}

export { DialogMultiArea };
