import React, { Fragment } from 'react';
import { Box, Grid, Paper } from "@material-ui/core";
import InfiniteScroll from 'react-infinite-scroll-component';
import { Loading } from "./../../components";
import { useSelector, useDispatch } from "react-redux";
import { reportActions } from "./../../actions";
import { useHistory } from "react-router-dom";
import { useStyles } from "./styles";
import { ReportCard } from "./ReportCard";
import { useTranslation } from 'react-i18next';
import { ReportEmptyState } from './ReportEmptyState';

export const ReportCards = ({ arrItems }) => {
  const { loading, report, config } = useSelector(state => state.report)
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  function loadMoreIdeas() {
    if (!loading) {
      //dispatch(reportActions.getReportData(page, sort, search));
    }
  }

  return (
    <Fragment>
      { arrItems.cardContent.length > 0 ? (
        <InfiniteScroll
          className={classes.infiniteScroll}
          dataLength={arrItems.cardContent.length}
          next={loadMoreIdeas}
          hasMore={true}
          scrollThreshold={'100%'}
          loader={
            loading && (
              <Box display="flex" justifyContent="center" alignItems="center">
                <Loading />
              </Box>
            )
          }
        >
          <Box width="100%">
            <Grid container spacing={4} className={classes.cardItemWrapper}>
              { arrItems.cardContent.map((item, key) => (
                <Grid item xs={12} key={key} className={classes.cardItem}>
                  <ReportCard reportItems={arrItems} reportItem={item} index={key} /> 
                </Grid>
              ))}
            </Grid>
          </Box>
        </InfiniteScroll>
      ) : (
        <ReportEmptyState />
      )}
    </Fragment>
  )
}
