import React from 'react';

import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  Table,
  TableBody,
  TablePagination,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { StatusIdea } from '../../components';
import { tableCss } from './TableCss';
import { dateFormat, truncate } from '../../helpers';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ideaActions, ideaCreateActions } from '../../actions';
import { TableMenu } from './TableMenu';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ViewIcon from '@material-ui/icons/Visibility';
import { CustomTooltip } from '../Shared';

function IdeaTable({ idea, ideas, paging, history, dialog, setIdea }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const language = useSelector((state) => state.language);
  const styleProps = {
    text: t('linhas_por_pagina'),
  };
  const tableClasses = tableCss(styleProps);

  const columns = [
    { id: 'code', label: t('codigo') },
    { id: 'date', label: t('data') },
    { id: 'title', label: t('titulo_da_ideia') },
    { id: 'challenge', label: t('desafio') },
    { id: 'unitLocal', label: t('unidade_de_negocio') },
    { id: 'status', label: t('status') },
    { id: 'actions', label: '' },
  ];

  function selectRow(idea) {
    if (idea.statusIdea?.code !== 'DRAFT') {
      // dispatch(ideaActions.getIdeaDetails(idea._id, 'general'));
    }
  }

  function openDialogDeleteDraft(idea) {
    dialog.setOpentDialog(true);
    setIdea(idea);
  }

  function openDialogSendIdea(idea) {
    dispatch(ideaCreateActions.getDraft(idea._id));
  }

  function handleView(id) {
    history.push(`/idea/${id}/general`);
  }

  function handleEdit(id) {
    history.push(`/idea-edit/${id}`);
  }

  function handleChangePage(event, page) {
    let newPage = page + 1;
    dispatch(
      ideaActions.getIdeasUser(
        newPage,
        paging.sort,
        paging.search,
        paging.limit,
        paging.statusCode
      )
    );
  }

  function handleChangeRowsPerPage(event) {
    var limit = event.target.value || 10;
    dispatch(
      ideaActions.getIdeasUser(
        1,
        paging.sort,
        paging.search,
        limit,
        paging.statusCode
      )
    );
  }

  return (
    <div style={{ display: 'flex' }}>
      <Paper className={tableClasses.paperRoot}>
        <TableContainer>
          <Table
            stickyHeader
            aria-label="idea table"
            classes={{ root: tableClasses.table }}
          >
            <TableHead
              style={{
                height: '70px'
              }}>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column._id}
                    classes={{ root: tableClasses.tableCellHeader }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {ideas &&
                ideas.map((idea) => {
                  return (
                    <TableRow
                      hover
                      key={idea._id}
                      onClick={() => selectRow(idea)}
                      style={{ cursor: 'pointer', height: 60 }}
                      classes={{ root: tableClasses.tableRow }}
                    >
                      <TableCell
                        classes={{ root: tableClasses.tableCellRadiusLeft }}
                      >
                        {idea.code}
                      </TableCell>
                      <TableCell classes={{ root: tableClasses.tableCell }}>
                        {dateFormat(idea.createdAt, language)}
                      </TableCell>
                      <TableCell
                        className={tableClasses.oneLine}
                        classes={{ root: tableClasses.tableCell }}
                      >
                        <CustomTooltip 
                          title={idea.name} 
                          aria-label={idea.name} 
                          placement="bottom-start"
                        >
                          <Typography 
                            variant="subtitle2" 
                            className={tableClasses.link} 
                            onClick={() => handleView(idea._id)}
                          >
                        {truncate(idea.name, 30)}
                          </Typography>
                        </CustomTooltip>
                      </TableCell>
                      <TableCell
                        className={tableClasses.oneLine}
                        classes={{ root: tableClasses.tableCell }}
                      >
                        {idea.challenge && idea.challenge.name}
                      </TableCell>
                      <TableCell
                        className={tableClasses.oneLine}
                        classes={{ root: tableClasses.tableCell }}
                      >
                        {
                          idea.businessUnitLocal && idea.businessUnitLocal.length > 0 &&
                          truncate(idea.businessUnitLocal[0]?.local?.name, 30)}
                      </TableCell>
                      <TableCell classes={{ root: tableClasses.tableCell }}>
                        {idea.statusIdea && (
                          <StatusIdea status={idea.statusIdea} />
                        )}
                      </TableCell>
<TableMenu>
                        <List>
                          {idea.statusIdea?.code !== 'DRAFT' && (
                            <ListItem onClick={() => handleView(idea._id)} button>
                            <ListItemIcon>
                              <ViewIcon />
                            </ListItemIcon>
                            <ListItemText
                              primary={<Typography>
                                {t('visualizar')}
                              </Typography>
                              }
                            />
                          </ListItem>
                          )}
                          {(idea.statusIdea?.code !== 'CANCELED' && idea.statusIdea?.code !== 'CONCLUDED' && idea.statusIdea?.code !== 'UNQUALIFIED'  ) && (
                          <ListItem
                            button
                            onClick={() => idea.statusIdea?.code === "DRAFT" 
                              ? openDialogSendIdea(idea) 
                              : handleEdit(idea._id)
                            }
                          >
                            <ListItemIcon>
                              <EditIcon />
                            </ListItemIcon>
                            <ListItemText
                              primary={<Typography>
                                {t('editar')}
                              </Typography>
                              }
                            />
                          </ListItem>
                          )}
                         {idea.statusIdea?.code === 'DRAFT' && (
                            <ListItem onClick={() => openDialogDeleteDraft(idea)} button>
                            <ListItemIcon>
                              <DeleteIcon />
                            </ListItemIcon>
                            <ListItemText
                              primary={<Typography>
                                {t('deletar')}
                              </Typography>
                              }
                            />
                          </ListItem>)}
                        </List>
                      </TableMenu>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          classes={{
            input: tableClasses.paginationInput,
            spacer: tableClasses.paginationSpacer,
            caption: tableClasses.paginationCaption,
            toolbar: tableClasses.paginationToolbar,
          }}
          rowsPerPageOptions={[10, 20, 30, 40, 50]}
          count={paging.total}
          rowsPerPage={paging.limit}
          page={paging.page - 1}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage={t('exibindo')}
          style={{zIndex: 10, position: 'relative'}}
        />
      </Paper>

    </div >
  );
}

export { IdeaTable };
