export const personalizedClassificationConstants = {

  GET_CLASSIFICATION_PERSONALIZED_IMAGINE_REQUEST: 'GET_CLASSIFICATION_PERSONALIZED_IMAGINE_REQUEST',
  GET_CLASSIFICATION_PERSONALIZED_IMAGINE_SUCCESS: 'GET_CLASSIFICATION_PERSONALIZED_IMAGINE_SUCCESS',
  GET_CLASSIFICATION_PERSONALIZED_IMAGINE_FAILURE: 'GET_CLASSIFICATION_PERSONALIZED_IMAGINE_FAILURE',

  POST_CLASSIFICATION_PERSONALIZED_IMAGINE_REQUEST: 'POST_CLASSIFICATION_PERSONALIZED_IMAGINE_REQUEST',
  POST_CLASSIFICATION_PERSONALIZED_IMAGINE_SUCCESS: 'POST_CLASSIFICATION_PERSONALIZED_IMAGINE_SUCCESS',
  POST_CLASSIFICATION_PERSONALIZED_IMAGINE_FAILURE: 'POST_CLASSIFICATION_PERSONALIZED_IMAGINE_FAILURE',

  SET_CLASSIFICATION_PERSONALIZED_IMAGINE_SUCCESS: 'SET_CLASSIFICATION_PERSONALIZED_IMAGINE_SUCCESS',
  SET_CLASSIFICATION_PERSONALIZED_IMAGINE_FAILURE: 'SET_CLASSIFICATION_PERSONALIZED_IMAGINE_FAILURE',
  SET_CLASSIFICATION_PERSONALIZED_IMAGINE_REQUEST: 'SET_CLASSIFICATION_PERSONALIZED_IMAGINE_REQUEST',

  PUT_CLASSIFICATION_PERSONALIZED_IMAGINE_REQUEST: 'PUT_CLASSIFICATION_PERSONALIZED_IMAGINE_REQUEST',
  PUT_CLASSIFICATION_PERSONALIZED_IMAGINE_SUCCESS: 'PUT_CLASSIFICATION_PERSONALIZED_IMAGINE_SUCCESS',
  PUT_CLASSIFICATION_PERSONALIZED_IMAGINE_FAILURE: 'PUT_CLASSIFICATION_PERSONALIZED_IMAGINE_FAILURE',

  DELETE_ALL_CLASSIFICATION_PERSONALIZED_IMAGINE_REQUEST: 'DELETE_ALL_CLASSIFICATION_PERSONALIZED_IMAGINE_REQUEST',
  DELETE_ALL_CLASSIFICATION_PERSONALIZED_IMAGINE_SUCCESS: 'DELETE_ALL_CLASSIFICATION_PERSONALIZED_IMAGINE_SUCCESS',
  DELETE_ALL_CLASSIFICATION_PERSONALIZED_IMAGINE_FAILURE: 'DELETE_ALL_CLASSIFICATION_PERSONALIZED_IMAGINE_FAILURE',

  DELETE_CLASSIFICATION_PERSONALIZED_IMAGINE_REQUEST: 'DELETE_CLASSIFICATION_PERSONALIZED_IMAGINE_REQUEST',
  DELETE_CLASSIFICATION_PERSONALIZED_IMAGINE_SUCCESS: 'DELETE_CLASSIFICATION_PERSONALIZED_IMAGINE_SUCCESS',
  DELETE_CLASSIFICATION_PERSONALIZED_IMAGINE_FAILURE: 'DELETE_CLASSIFICATION_PERSONALIZED_IMAGINE_FAILURE',

  SET_CLASSIFICATION_PERSONALIZED_CONTENT: 'SET_CLASSIFICATION_PERSONALIZED_CONTENT',
  DELETE_MANY_SUCCESS: 'DELETE_MANY_SUCCESS',
  DELETE_MANY_FAILURE: 'DELETE_MANY_FAILURE',
  CLEAR: 'CLEAR',

  SET_LOADING: 'SET_LOADING',
};
