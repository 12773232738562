import { personalizedClassificationConstants } from '../constants';
import { PersonalizedClassificationService } from '../services';
import { history } from '../helpers';
import { alertActions } from './alert.actions';

export const personalizedClassificationActions = {
  getPersonalizedClassification,
  postPersonalizedClassification,
  deleteOnePersonalizedClassification,
  deleteAllPersonalizedClassification,
  setPersonalizedClassification,
  setPersonalizedClassificationContent,
  putPersonalizedClassification,
  deleteMany,
  clear,
  setLoading,
};

function getPersonalizedClassification(
  page = 1,
  sort = { order: 1 },
  query,
  limit,

) {
  return (dispatch) => {
    dispatch(request());
    PersonalizedClassificationService
      .getClassificationPersonalized(page, sort, query, limit)
      .then((classificationPersonalized) => {
        dispatch(success(classificationPersonalized, query));
      })
      .catch((error) => {
        dispatch(failure(error));
        console.log(error);
      });
  };

  function request() {
    return { type: personalizedClassificationConstants.GET_CLASSIFICATION_PERSONALIZED_IMAGINE_REQUEST };
  };

  function success(classificationPersonalized, query) {
    const payload = {
      classificationPersonalized,
    };

    return { type: personalizedClassificationConstants.GET_CLASSIFICATION_PERSONALIZED_IMAGINE_SUCCESS, payload };
  };

  function failure(error) {
    return { type: personalizedClassificationConstants.GET_CLASSIFICATION_PERSONALIZED_IMAGINE_FAILURE };
  };
};

function postPersonalizedClassification(personalizedClassification, successMessage) {
  return (dispatch) => {
    dispatch(request());
    PersonalizedClassificationService
      .sendPersonalizedClassifications(personalizedClassification)
      .then((classificationPersonalized) => {
        dispatch(success(classificationPersonalized));
        dispatch(alertActions.success(successMessage));
        history.push('/preferences/personalized-classification');
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };

  function request() {
    return { type: personalizedClassificationConstants.POST_CLASSIFICATION_PERSONALIZED_IMAGINE_REQUEST };
  };

  function success(classificationPersonalized, query) {
    const payload = {
      classificationPersonalized,

    };

    return { type: personalizedClassificationConstants.POST_CLASSIFICATION_PERSONALIZED_IMAGINE_SUCCESS, payload };
  };

  function failure(error) {
    return { type: personalizedClassificationConstants.POST_CLASSIFICATION_PERSONALIZED_IMAGINE_FAILURE };
  };
};

function deleteAllPersonalizedClassification() {
  return (dispatch) => {
    dispatch(request());
    PersonalizedClassificationService
      .deleteAllPersonalizedClassifications()
      .then(() => {
        dispatch(success());
        history.push('/preferences/personalized-classification');
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };

  function request() {
    return { type: personalizedClassificationConstants.DELETE_ALL_CLASSIFICATION_PERSONALIZED_IMAGINE_REQUEST };
  };

  function success() {
    return { type: personalizedClassificationConstants.DELETE_ALL_CLASSIFICATION_PERSONALIZED_IMAGINE_SUCCESS };
  };

  function failure(error) {
    return { type: personalizedClassificationConstants.DELETE_ALL_CLASSIFICATION_PERSONALIZED_IMAGINE_FAILURE };
  };
};

function deleteOnePersonalizedClassification(id=null, index) {
  return (dispatch) => {
      dispatch(success());
  };

  function success() {
    return { type: personalizedClassificationConstants.DELETE_CLASSIFICATION_PERSONALIZED_IMAGINE_SUCCESS, payload: { id, index } };
  };

};

function setPersonalizedClassification(personalizedClassification) {
  return dispatch => {
    dispatch(request());
    dispatch(success(personalizedClassification));
  };

  function request() {
    return { type: personalizedClassificationConstants.SET_CLASSIFICATION_PERSONALIZED_IMAGINE_REQUEST }
  };

  function success(payload) {
    return { type: personalizedClassificationConstants.SET_CLASSIFICATION_PERSONALIZED_IMAGINE_SUCCESS, payload}
  };
};

function setPersonalizedClassificationContent({ field, value, index }) {
  return dispatch => {
    dispatch({ type: personalizedClassificationConstants.SET_CLASSIFICATION_PERSONALIZED_CONTENT, payload: { field, value, index } });
  };
};

function putPersonalizedClassification(personalizedClassificationsToPut = []) {
   return dispatch => {
    dispatch({ type: personalizedClassificationConstants.PUT_CLASSIFICATION_PERSONALIZED_IMAGINE_REQUEST });
    PersonalizedClassificationService.putPersonalizedClassification(personalizedClassificationsToPut).then(() => {
      dispatch({ type: personalizedClassificationConstants.PUT_CLASSIFICATION_PERSONALIZED_IMAGINE_SUCCESS });
      history.push('/preferences/personalized-classification');
    }).catch(err => {
        dispatch({ type: personalizedClassificationConstants.PUT_CLASSIFICATION_PERSONALIZED_IMAGINE_FAILURE });
        console.log(err);
    });
  };
};

function deleteMany(classificationsToDelete) {
  return dispatch => {
    PersonalizedClassificationService.deleteMany(classificationsToDelete).then(() => {
      dispatch({ type: personalizedClassificationConstants.DELETE_MANY_SUCCESS });
      history.push('/preferences/personalized-classification');
    }).catch((err) => {
      dispatch({ type: personalizedClassificationConstants.DELETE_MANY_FAILURE });
      console.log(err);
    });
  };  
};

function clear() {
  return dispatch => dispatch({ type: personalizedClassificationConstants.CLEAR });
};

function setLoading(payload=false) {
  return dispatch => dispatch({ type: personalizedClassificationConstants.SET_LOADING, payload });
};