import makeStyles from "@material-ui/styles/makeStyles"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(4),
  },
  containerLoading: {
    display: 'flex',
    justifyContent: 'center',
  },
  fixed: {
    position: 'sticky',
    boxSizing: 'border-box',
    height: 'calc(100vh - 70px)',
    top: '70px',
  },
  fab: {
    position: 'fixed',
    zIndex: 1200,
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

export default useStyles