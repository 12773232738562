import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
} from '@material-ui/core';

import { tableCss } from './TableCss';
import { SkeletonTemplateCrud } from '../Skeletons';
import { crudTemplateActions } from '../../actions/crudTemplate.actions';
import { selectEndPoint } from '../../helpers/selectEndPointToTemplate';

export function TemplateCrudTable({ tableInfo }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const tableClasses = tableCss({ text: t('linhas_por_pagina') });
  const { paging, loadings: { getAll }, type } = useSelector(state => state.crudTemplate);

  function handleChangePage(_e, page) {
    let newPage = page + 1;

    dispatch(crudTemplateActions.setPage(newPage));
    dispatch(crudTemplateActions.getAll({ endPoint: selectEndPoint(type), page: newPage, sort: paging?.sort, limit: paging?.limit }));
  }

  function handleChangeRowsPerPage({ target: value }) {
    let limit = value.value || 10;
   
    dispatch(crudTemplateActions.setLimit(limit));
    dispatch(crudTemplateActions.getAll({ endPoint: selectEndPoint(type), page: paging?.page, sort: paging?.sort, limit }));
  }

  return (
    <>
      {getAll ? (
        <SkeletonTemplateCrud />
      ) : (
        <Paper className={tableClasses.paperRoot}>
          <TableContainer>
            <Table
              stickyHeader
              aria-label="crud table"
              classes={{ root: tableClasses.table }}
            >
              <TableHead>
                <TableRow>
                  {tableInfo && tableInfo.columns.map((column) => (
                    <TableCell
                      key={column.id}
                      classes={{ root: tableClasses.tableCellHeader }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableInfo && tableInfo.data.map((item, index) => {
                  return (
                    <TableRow
                      hover
                      key={index}
                      style={{ cursor: 'pointer' }}
                      classes={{ root: tableClasses.tableRow }}
                    >
                      {item}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            component="div"
            classes={{
              input: tableClasses.paginationInput,
              spacer: tableClasses.paginationSpacer,
              caption: tableClasses.paginationCaption,
              toolbar: tableClasses.paginationToolbar,
            }}
            rowsPerPageOptions={[10, 20, 30, 40, 50]}
            count={paging.total}
            rowsPerPage={paging.limit}
            page={paging.page - 1}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage={t('exibindo')}
          />
        </Paper>
      )}
    </>
  );
}